import html from "./MainPage.html"
import Router from 'Router'

export default class MainPage extends HTMLElement {

    private viewContainer!: HTMLDivElement

    constructor() {
        super()
    }

    public connectedCallback() {
        this.innerHTML = html
        this.viewContainer = this.querySelector<HTMLDivElement>("#mainPageViewContainer")!

        Router.setRenderCallback((view) => this.showView(view))

        if (location.hash.length === 0) {
            Router.goto("/view/organisations")
        }
    }

    private showView(view: HTMLElement) {
        this.viewContainer.innerHTML = ""
        this.viewContainer.appendChild(view)
    }
}

customElements.define("mo-page", MainPage)