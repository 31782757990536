import { OrganisationItem } from 'organisation/OrganisationModel'

export class UserListResponse {
    public organisation!: OrganisationItem
    public users!: UserItem[]
}

export class UserItem {
    public id!: number
    public mailAddress!: string
    public firstName?: string
    public lastName?: string
    public phoneNumber?: string
    public isAdministrator!: boolean
}

export class UserEditRequest {
    public mailAddress = ""
    public firstName = ""
    public lastName = ""
    public phoneNumber = ""
    public password = ""
    public isAdministrator = false
}