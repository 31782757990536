import Navigo from 'navigo'
import Session from 'Session'

export default class Router {

    private static _instance: Router

    private static get instance() {
        if (!Router._instance) {
            Router._instance = new Router()
        }
        return Router._instance
    }

    private navigo: Navigo

    private showViewCallback: (element: HTMLElement) => void = () => { }


    constructor() {
        this.navigo = new Navigo("http://localhost:9000/", true, "#")
        this.navigo.notFound(() => Router.navigate("/campaigns"))
        this.navigo.hooks({ before: (done) => Router.checkSession(done) })
    }

    private static checkSession(done: (supress?: boolean | undefined) => void) {
        if (!Session.get().isValid()) {
            done(false)
            document.dispatchEvent(new Event("unauthorized"))
        } else {
            done()
        }
    }

    public static init() {
        Router.instance.navigo.resolve()
    }

    public static setRenderCallback(callback: (element: HTMLElement) => void) {
        Router.instance.showViewCallback = callback
    }

    public static register(url: string, creator: (params: any) => HTMLElement) {
        Router.instance.navigo.on(url, (params) => Router.instance.showViewCallback(creator(params)))
    }

    public static goto(url: string) {
        Router.instance.navigo.navigate(url)
    }

    public static navigate(url: string, params?: any) {
        if (!params) {
            Router.goto(url)
        }
        else {
            let result = url
            for (const key in params) {
                result = result.toString().replace(':' + key, params[key])
            }
            Router.goto(result)
        }
    }
}