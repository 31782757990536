import { OrganisationItem, OrganisationEditRequest } from './OrganisationModel'
import { toElement } from 'view/Views'
import $ from "jquery"
import "bootstrap"
import Translator from 'i18n/Translator'
import DataBinder from 'base/DataBinder'
import Client from 'rest/Client'
import RestResponse from 'rest/RestResponse'

export default class OrganisationEditDialog {


    private dialog?: HTMLElement
    constructor(private organisation?: OrganisationItem) {

    }

    public show() {
        this.createDialog()
        $(".modal").modal({ backdrop: "static" }).modal("show").on("hidden.bs.modal", () => this.cleanup())
    }

    private createDialog() {
        this.dialog = toElement(`
        <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Firma bearbeiten</h5>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="kt-form kt-form--label-right">
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="organisation.name">Firmenname</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input class="form-control" type="text" data-binding="name" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="organisation.street">Strasse</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input class="form-control" type="text" data-binding="street">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="organisation.postalcode">Postleitzahl</label>
                                    <div class="col-lg-6 col-xl-3">
                                        <input class="form-control" type="text" data-binding="postalcode">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="organisation.city">Ort</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input class="form-control" type="text" data-binding="city">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="organisation.phonenumber">Telefonnummer</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input class="form-control" type="text" data-binding="phoneNumber">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-i18n-innerhtml="cancel">Abbrechen</button>
                        <button id="oedSave" type="button" class="btn btn-primary" data-i18n-innerhtml="save">Speichern</button>
                    </div>
                </div>
            </div>
        </div>
        `).firstElementChild! as HTMLElement

        const saveButton = this.dialog.querySelector("#oedSave")
        saveButton?.addEventListener("click", () => this.onSaveClicked())

        Translator.translate(this.dialog)
        if (this.organisation) {
            DataBinder.toView(this.dialog, this.organisation)
        }

        document.body.appendChild(this.dialog)
    }

    private async onSaveClicked() {

        if (!this.dialog?.querySelector<HTMLFormElement>("form")?.checkValidity()) {
            return
        }

        const organisationEditRequest = new OrganisationEditRequest()
        DataBinder.fromView(this.dialog!, organisationEditRequest)

        let response: RestResponse<void>
        if (this.organisation) {
            response = await Client.patch(`/organisation/${this.organisation.id}`, organisationEditRequest)
        } else {
            response = await Client.post("/organisation", organisationEditRequest)
        }

        if (response.isSuccessful()) {
            $(".modal").modal("hide")
            document.dispatchEvent(new Event("organisationUpdated"))
        }
    }

    private cleanup() {
        if (this.dialog) {
            document.body.removeChild(this.dialog)
        }
    }

}