import 'login/LoginPage'
import 'organisation/OrganisationListView'
import 'user/UserListView'
import LoginPage from 'login/LoginPage'
import Session from 'Session'
import MainPage from 'base/MainPage'
import Router from 'Router'

export default class App {
    private viewContainer!: HTMLDivElement

    public start() {
        console.log("start app")

        this.viewContainer = document.querySelector<HTMLDivElement>("#viewContainer")!

        document.addEventListener("loggedIn", () => this.onLoggedInEvent())
        document.addEventListener("unauthorized", () => this.show(new LoginPage()))

        if (Session.get().isValid()) {
            this.show(new MainPage())
        } else {
            this.show(new LoginPage())
            location.hash = ""
        }

        Router.init()
    }

    private onLoggedInEvent() {
        this.show(new MainPage())
        Router.goto("/view/organisations")
    }

    public show(view: HTMLElement) {
        this.viewContainer.innerHTML = ""
        this.viewContainer.appendChild(view)
    }
}

document.addEventListener("DOMContentLoaded", () => new App().start())