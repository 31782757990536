import Session from "../Session"
import RestResponse from './RestResponse'
import download from "downloadjs"

declare const process: any

export default class Client {
    private static _instance: Client

    private static get instance(): Client {
        if (!this._instance) {
            this._instance = new Client()
        }
        return this._instance
    }

    public static async get<T>(path: string): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.GET)
    }

    public static async put<T>(path: string, body: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.PUT, body)
    }

    public static async patch<T>(path: string, body: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.PATCH, body)
    }

    public static async post<T>(path: string, body?: any): Promise<RestResponse<T>> {
        return this.instance.fetch(path, Method.POST, body)
    }

    public static async delete(path: string): Promise<RestResponse<void>> {
        return this.instance.fetch(path, Method.DELETE)
    }

    public static async download(path: string, filename: string, mimeType?: string) {
        const response = await window.fetch(
            url(path),
            {
                method: "GET",
                headers: this.instance.getHeaders()
            })
        if (response.ok) {
            download(await response.blob(), filename, mimeType)
        }
    }

    private async fetch<T>(path: string, method: string, body?: any): Promise<RestResponse<T>> {
        const response = await window.fetch(
            url(path),
            {
                method: method,
                headers: this.getHeaders(),
                body: body ? JSON.stringify(body) : undefined
            })

        if (response.status === 401) {
            Session.get().invalidate()
            document.dispatchEvent(new Event("unauthorized"))
        }

        return await RestResponse.from<T>(response)
    }

    private getHeaders(): Headers {
        let headers = new Headers()
        headers.set("Content-Type", "application/json; charset=utf-8")
        if (Session.get().isValid()) {
            headers.set("Authorization", `Bearer ${Session.get().authToken}`)
        }
        return headers
    }

}

export const url: (path: string) => string = path => {
    if (process.env.NODE_ENV === "development") {
        return `${location.protocol}//localhost:8080/mailingo/system/${path.replace("/", "")}`
    } else {
        return `${location.protocol}//${location.host}/api/system/${path.replace("/", "")}`
    }
}

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE"
}