export class OrganisationItem {

    public id!: number
    public name!: string
    public street?: string
    public postalcode?: string
    public city?: string
    public countryCodeAlpha2?: string
    public phoneNumber?: string

}

export class OrganisationEditRequest {
    public name = ""
    public street = ""
    public postalcode = ""
    public city = ""
    public countryCodeAlpha2 = "DE"
    public phoneNumber = ""
} 