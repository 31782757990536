import { OrganisationItem } from 'organisation/OrganisationModel'

export class CampaignStatus {
    static CREATED = "CREATED"
    static FILE_UPLOADED = "FILE_UPLOADED"
    static MAPPING_DONE = "MAPPING_DONE"
    static READY_TO_EXPORT = "READY_TO_EXPORT"
    static EXPORTED = "EXPORTED"
}

export class CampaignItem {
    public id!: number
    public name!: string
    public description?: string
    public status!: string
    public addressCount!: number
    public createdDateTime!: string
}

export class CampaignListSystemResponse {
    public organisation!: OrganisationItem
    public campaigns!: CampaignItem[]
}