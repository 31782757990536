import htmlTemplate from "./OrganisationListView.html"
import { View } from "view/Views"
import Client from 'rest/Client'
import { OrganisationItem } from './OrganisationModel'
import $ from "jquery"
import "bootstrap"
import "datatables.net-bs4"
import OrganisationEditDialog from './OrganisationEditDialog'
import { render, html } from "lit-html"
import Router from 'Router'
import CampaignListView from 'campaign/CampaignListView'

const URL = "organisations"
@View("mo-organisation-list", URL, OrganisationListView.create)
export default class OrganisationListView extends HTMLElement {

    static create() {
        return new OrganisationListView()
    }

    private organisationList!: HTMLTableSectionElement

    public connectedCallback() {
        this.innerHTML = htmlTemplate
        this.organisationList = this.querySelector<HTMLTableSectionElement>("#organisationList")!
        document.addEventListener("organisationUpdated", () => this.updatePatientList())

        this.querySelector<HTMLButtonElement>("#olAddOrganisation")?.addEventListener("click", () => this.onAddOrganisationClicked())

        this.loadPatient()
    }

    private onAddOrganisationClicked() {
        new OrganisationEditDialog().show()
    }

    private updatePatientList() {
        $("#organisationTable").DataTable().destroy()
        this.loadPatient()
    }

    private async loadPatient() {
        this.organisationList.innerHTML = ""
        const response = await Client.get<OrganisationItem[]>("/organisation")
        if (response.isSuccessful()) {

            response.entity?.forEach(value => {
                this.organisationList.appendChild(new OrganisationListItem(value).render())
            })

            $("#organisationTable").DataTable({
                "columnDefs": [
                    { "width": "150px", "targets": 4 }
                ]
            })
        }
    }

}

class OrganisationListItem {

    private row: HTMLTableRowElement

    constructor(private organisation: OrganisationItem) {
        this.row = document.createElement("tr")
    }

    public render() {
        render(html`
            <th scope="row">${this.organisation.name}</th>
            <td>${this.organisation.street || ""}</td>
            <td>${this.organisation.postalcode || ""} ${this.organisation.city || ""}</td>
            <td>${this.organisation.phoneNumber || ""}</td>
            <td>
                <button @click=${() => this.onShowUsersCicked()} type="button" class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md">
                    <i class="fas fa-users"></i>
                </button>
                <button data-id="btnEdit" @click=${() => this.onEditClicked()} type="button" class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md">
                    <i class="fas fa-edit"></i>
                </button> 
                <button @click=${() => this.onShowCampaignsClicked()} type="button" class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </td>`, this.row)

        return this.row
    }

    private onEditClicked() {
        new OrganisationEditDialog(this.organisation).show()
    }

    private onShowUsersCicked() {
        Router.goto(`/view/organisations/${this.organisation.id}/users`)
    }

    private onShowCampaignsClicked() {
        CampaignListView.show(this.organisation.id)
    }
}