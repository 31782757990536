import { View } from "view/Views"
import Client from 'rest/Client'
import { CampaignItem, CampaignStatus, CampaignListSystemResponse } from './CampaignModel'
import $ from "jquery"
import "bootstrap"
import "datatables.net-bs4"
import { render, html } from "lit-html"
import Router from 'Router'
import Translator from 'i18n/Translator'
import YesNoDialog from 'base/YesNoDialog'
import { OrganisationItem } from 'organisation/OrganisationModel'

const URL = "/organisations/:id/campaigns"
@View("mo-campaign-list", URL, CampaignListView.create)
export default class CampaignListView extends HTMLElement {

    public static show(organisationId: number) {
        Router.navigate(URL, { id: organisationId })
    }

    static create(params: any) {
        return new CampaignListView(params.id)
    }

    constructor(private organisationId?: number) {
        super()
    }

    private campaigns: CampaignItem[] = []
    private organisation?: OrganisationItem

    public connectedCallback() {
        this.renderElement()
        this.loadCampaigns()
    }

    private renderElement() {
        render(html`
    <div class="kt-body">
        <div class="kt-content">
            <div class="kt-subheader kt-grid__item" id="kt_subheader">
                <div class="kt-container ">
                    <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">
                            Kampagnen <small>[${this.organisation?.name}]</small></h3>
                    </div>
                    <div class="kt-subheader__toolbar">
                        <div class="kt-subheader__wrapper"> </div>
                    </div>
                </div>
            </div>
            <div class="kt-container">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title"> Laufende Kampagnen </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <table id="campaignTable" class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Bezeichnung</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Anzahl Adressen</th>
                                    <th scope="col">Erstellt</th>
                                    <th scope="col">Aktionen</th>
                                </tr>
                            </thead>
                            <tbody id="campaignList">
                                ${this.campaigns.filter(c => c.status != CampaignStatus.EXPORTED).map(c => this.renderCampaignItem(c))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title"> Archivierte Kampagnen </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <button class="btn" type="button" data-toggle="collapse" data-target="#doneCampaignSection" aria-expanded="false" aria-controls="collapseExample">
                                    <i class="fas fa-chevron-up"></i><i class="fas fa-chevron-down"></i>
                                </button>
                            </div>
                        </div>
                    <div class="kt-portlet__body">
                        <div id="doneCampaignSection" class="collapse show">
                            <table id="doneCampaignTable" class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Bezeichnung</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Anzahl Adressen</th>
                                        <th scope="col">Erstellt</th>
                                        <th scope="col">Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody id="doneCampaignList">
                                    ${this.campaigns.filter(c => c.status === CampaignStatus.EXPORTED).map(c => this.renderCampaignItem(c))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`, this)
    }


    private async loadCampaigns() {
        const response = await Client.get<CampaignListSystemResponse>("/campaigns/organisation/" + this.organisationId)
        if (response.isSuccessful()) {
            this.campaigns = response.entity?.campaigns ?? []
            this.organisation = response.entity?.organisation
            this.renderElement()
            $("#campaignTable").DataTable()
            $("#doneCampaignTable").DataTable()
        }
    }

    private renderCampaignItem(campaign: CampaignItem) {
        return html`
            <tr>
                <td scope="row">${campaign.name}<br><em>${campaign.description ?? ""}</em></td>
                <td scope="row">${this.getCampaignStatusDescription(campaign)}</td>
                <td scope="row">${campaign.addressCount}</td>
                <td scope="row">${new Date(campaign.createdDateTime).toLocaleDateString()}</td>
                <td>${this.renderCampaignActionColumn(campaign)}</td>
            </tr>`
    }

    private getCampaignStatusDescription(campaign: CampaignItem) {
        const key = "campaign.status." + campaign.status.toString().toLowerCase()
        return Translator.translateWithKey(key)
    }

    private renderCampaignActionColumn(campaign: CampaignItem) {
        switch (campaign.status) {
            case CampaignStatus.READY_TO_EXPORT:
                return html`
                <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md" @click=${() => this.exportCampaign(campaign)} title="Exportieren"><i class="fas fa-download"></i></i></button>
                <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md" @click=${() => this.onArchiveCampaignClicked(campaign)} title="Archivieren"><i class="fas fa-archive"></i></i></button>`
            case CampaignStatus.EXPORTED:
                return html`
                <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md" @click=${() => this.exportCampaign(campaign)} title="Exportieren"><i class="fas fa-download"></i></i></button>
                <button class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md" @click=${() => this.onDeleteClicked(campaign.id)}><i class="fas fa-trash"></i></button>`
            case CampaignStatus.CREATED:
            case CampaignStatus.FILE_UPLOADED:
            case CampaignStatus.MAPPING_DONE:
            default:
                return ""
        }
    }

    private async onDeleteClicked(campaignId: number) {
        new YesNoDialog("Kampagne löschen", "Möchten Sie diese Kampagne und alle dazugehörigen Adressen wirklich löschen?", async () => {
            const response = await Client.delete(`/campaigns/${campaignId}`)
            if (response.isSuccessful()) {
                this.loadCampaigns()
            }
        }).show()
    }

    private exportCampaign(campaign: CampaignItem) {
        Client.download(`/campaigns/${campaign.id}/addresses/export`, `${campaign.name}.csv`, "text/csv")
    }

    private onArchiveCampaignClicked(campaign: CampaignItem) {
        new YesNoDialog("Kampagne archivieren", "Möchten Sie diese Kampagne wirklich archivieren?", () => this.archiveCampaign(campaign)).show()
    }

    private async archiveCampaign(campaign: CampaignItem) {
        const response = await Client.post(`/campaigns/${campaign.id}/archive`)
        if (response.isSuccessful()) {
            this.loadCampaigns()
        }
    }
}