import JsonUtil from './JsonUtil'

export default class RestResponse<T>{

    public entity: T | null

    public statusCode: number

    public httpResponse: Response

    public static async from<T>(response: Response): Promise<RestResponse<T>> {
        const entity = await this.tryParseEntity<T>(response)
        return new RestResponse<T>(response, entity)
    }

    private constructor(response: Response, entity: T | null) {
        this.httpResponse = response
        this.statusCode = response.status
        this.entity = entity
    }

    private static async tryParseEntity<T>(response: Response): Promise<T | null> {
        try {
            const jsonString = await response.text()
            return JSON.parse(jsonString, JsonUtil.parseByRegex) as T
        } catch {
            return null
        }
    }

    public isSuccessful() {
        return (this.statusCode === 200 && !!this.entity) || this.statusCode >= 201 && this.statusCode < 300
    }
}