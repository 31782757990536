export const GermanTranslation = {
    "save": "Speichern",
    "cancel": "Abbrechen",

    "organisation.name": "Name",
    "organisation.street": "Strasse",
    "organisation.postalcode": "Postleitzahl",
    "organisation.city": "Ort",
    "organisation.phonenumber": "Telefonnummer",

    "user.mailaddress": "E-Mailadresse",
    "user.firstname": "Vorname",
    "user.lastname": "Nachname",
    "user.phonenumber": "Telefonnummer",
    "user.password": "Passwort",
    "user.isAdmin": "Administrator",

    "campaign.status.created": "Angelegt",
    "campaign.status.file_uploaded": "Datei hochgeladen",
    "campaign.status.mapping_done": "Mapping erstellt",
    "campaign.status.ready_to_export": "Freigabe erteilt",
    "campaign.status.exported": "Abgeschlossen"
}