import { GermanTranslation } from './TranslationDe'

export default class Translator {

    public static translate(parentElement: HTMLElement) {

        const nodeInnerHtmlTranslations = parentElement.querySelectorAll<HTMLElement>("[data-i18n-innerhtml]")
        nodeInnerHtmlTranslations.forEach((element) => {
            const translationKey = element.dataset.i18nInnerhtml
            if (translationKey) {
                const translation = this.getTranslation()
                const value = translation[translationKey]
                if (value) {
                    element.innerHTML = value
                }
            }
        })
    }

    public static translateWithKey(key: string) {
        const translation = this.getTranslation()
        return translation[key] ?? key
    }


    private static getTranslation(): any {
        const browserLanguage = navigator.language
        switch (browserLanguage) {
            case "de-DE":
            default:
                return GermanTranslation
        }
    }
}