export default class JsonUtil
{

  private static ISO_8601_DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/;
  private static ISO_8601_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;


  public static parseByRegex(_: string, value: any)
  {
    if (typeof value === "string")
    {
      if (JsonUtil.ISO_8601_DATE_TIME_REGEX.test(value) || JsonUtil.ISO_8601_DATE_REGEX.test(value))
      {
        return new Date(value)
      }
    }
    return value;
  }
}