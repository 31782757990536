import ModalDialog from './ModalDialog'
import { TemplateResult, html } from 'lit-html'

export default class YesNoDialog extends ModalDialog {

    constructor(private title: string, private message: string, private yesListener?: () => void, private noListener?: () => void) {
        super()
    }

    createContent(): TemplateResult {
        return html`
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">${this.title}</h5>
        </div>
        <div class="modal-body">
            <p>${this.message}</p>
        </div>
        <div class="modal-footer">
            <button @click=${() => this.onNoClicked()}  type="button" class="btn btn-secondary" data-dismiss="modal">Nein</button>
            <button @click=${() => this.onYesClicked()} type="button" class="btn btn-primary" data-dismiss="modal">Ja</button>
        </div>
        `
    }
    private onNoClicked() {
        this.noListener?.call(this)
    }

    private onYesClicked() {
        this.yesListener?.call(this)
    }

}