import ModalDialog from 'base/ModalDialog'
import { TemplateResult, html } from 'lit-html'
import DataBinder from 'base/DataBinder'
import { UserItem, UserEditRequest } from './UserModel'
import Client from 'rest/Client'
import RestResponse from 'rest/RestResponse'

export default class UserEditDialog extends ModalDialog {


    constructor(private organisationId: number, private user?: UserItem) {
        super(false)
    }

    createContent(): TemplateResult {
        return html`
        <div class="modal-header">
            <h5 class="modal-title">Benutzer bearbeiten</h5>
        </div>
        <div class="modal-body">
            <form>
                <div class="kt-form kt-form--label-right">
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.mailaddress">E-Mailadresse</label>
                        <div class="col-lg-9 col-xl-6">
                            <input class="form-control" type="email" data-binding="mailAddress" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.firstname">Strasse</label>
                        <div class="col-lg-9 col-xl-6">
                            <input class="form-control" type="text" data-binding="firstName" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.lastname">Strasse</label>
                        <div class="col-lg-9 col-xl-6">
                            <input class="form-control" type="text" data-binding="lastName" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.phonenumber">Telefonnummer</label>
                        <div class="col-lg-9 col-xl-6">
                            <input class="form-control" type="text" data-binding="phoneNumber">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.password">Passwort</label>
                        <div class="col-lg-9 col-xl-6">
                            <input class="form-control" type="password" data-binding="password">
                        </div>
                    </div>
                    <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label" data-i18n-innerhtml="user.isAdmin">Passwort</label>
                        <div class="col-lg-9 col-xl-6">
                            <label class="kt-checkbox">
                                <input type="checkbox" data-binding="isAdministrator">
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" data-i18n-innerhtml="cancel">Abbrechen</button>
            <button @click=${() => this.onSaveClicked()} type="button" class="btn btn-primary" data-i18n-innerhtml="save">Speichern</button>
        </div>`
    }

    initialize(element: HTMLElement) {
        if (this.user) {
            DataBinder.toView(element, this.user)
        }
    }

    private async onSaveClicked() {

        if (!this.container?.querySelector<HTMLFormElement>("form")?.checkValidity()) {
            return
        }

        const userEditRequest = new UserEditRequest()
        DataBinder.fromView(this.container, userEditRequest)

        let response: RestResponse<void>
        if (this.user) {
            response = await Client.patch<void>(`/organisation/${this.organisationId}/user/${this.user.id}`, userEditRequest)
        } else {
            response = await Client.post<void>(`/organisation/${this.organisationId}/user/`, userEditRequest)
        }

        if (response.isSuccessful()) {
            this.close()
            document.dispatchEvent(new Event("userUpdated"))
        }
    }
}