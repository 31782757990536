export default class DataBinder {

    public static toView(element: HTMLElement, data: any) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const viewElement = element.querySelector(`[data-binding=${key}]`)
                if (viewElement instanceof HTMLInputElement) {
                    DataBinder.toHtmlInput(viewElement, data, key)
                } else if (viewElement instanceof HTMLTextAreaElement) {
                    viewElement.value = data[key]
                }
            }
        }
        return data
    }

    private static toHtmlInput(viewElement: HTMLInputElement, data: any, key: string) {
        switch (viewElement.type) {
            case "radio":
            case "checkbox":
                viewElement.checked = data[key] as boolean
                break
            default:
                viewElement.value = data[key] as string
                break
        }
    }

    public static fromView<T extends object>(element: HTMLElement, data: T): T {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const viewElement = element.querySelector<HTMLElement>(`[data-binding=${key}]`)
                if (viewElement instanceof HTMLInputElement) {
                    DataBinder.fromHtmlInput(viewElement, data, key)
                } else if (viewElement instanceof HTMLTextAreaElement) {
                    (data[key] as any) = viewElement.value
                }
            }
        }
        return data
    }

    private static fromHtmlInput(viewElement: HTMLInputElement, data: any, key: string) {
        switch (typeof (data[key])) {
            case "boolean":
                data[key] = viewElement.checked
                break
            case "number":
                data[key] = viewElement.valueAsNumber
                break
            case "string":
            default:
                data[key] = viewElement.value
                break
        }
    }
}