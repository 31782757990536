import Client from 'rest/Client'
import $ from "jquery"
import "bootstrap"
import "datatables.net-bs4"
import { render, html } from "lit-html"
import { UserItem, UserListResponse } from './UserModel'
import UserEditDialog from './UserEditDialog'
import { View } from 'view/Views'


const URL = "organisations/:organisationId/users"
@View("mo-user-list", URL, UserListView.create)
export default class UserListView extends HTMLElement {

    static create(params: any) {
        return new UserListView(params.organisationId)
    }

    private userList!: HTMLTableSectionElement
    private userListResponse?: UserListResponse

    constructor(private organisationId: number) {
        super()
    }

    public connectedCallback() {
        this.renderContent()
        this.userList = this.querySelector<HTMLTableSectionElement>("#userList")!
        document.addEventListener("userUpdated", () => this.updatePatientList())

        this.loadPatient()
    }

    private renderContent() {
        render(html`
            <div class="kt-content">
                <div class="kt-subheader--transparent">
                    <div class="kt-container">
                        <h3>Benutzerverwaltung [${this.userListResponse?.organisation.name}]</h3>
                    </div>
                </div>
                <div class="kt-container">
                    <div class="kt-portlet">
                        <div class="kt-portlet__head kt-portlet__head--lg">
                            <div class="kt-portlet__head-label"></div>
                            <div class="kt-portlet__head-toolbar">
                                <div class="kt-portlet__head-wrapper">
                                    <div class="kt-portlet__head-actions">
                                        <a @click=${() => this.onAddUserClicked()} class="btn btn-brand btn-elevate btn-icon-sm clickable">
                                            <i class="fas fa-plus"></i> Benutzer anlegen
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__body">
                            <table id="userTable" class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">E-Mailadresse</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Telefonnummer</th>
                                        <th scope="col">Administrator</th>
                                        <th scope="col" style="width: 80px"></th>
                                    </tr>
                                </thead>
                                <tbody id="userList">

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>`
            , this)
    }


    private onAddUserClicked() {
        new UserEditDialog(this.userListResponse!.organisation.id).show()
    }

    private updatePatientList() {
        $("#userTable").DataTable().destroy()
        this.loadPatient()
    }

    private async loadPatient() {
        this.userList.innerHTML = ""
        const response = await Client.get<UserListResponse>(`/organisation/${this.organisationId}/user`)
        if (response.isSuccessful() && response.entity) {
            this.userListResponse = response.entity
            this.renderContent()

            response.entity?.users.forEach(value => {
                this.userList.appendChild(new UserListItem(this.userListResponse!.organisation.id, value).render())
            })

            $("#userTable").DataTable({
                "columnDefs": [
                    { "width": "100px", "targets": 4 }
                ]
            })
        }
    }

}


class UserListItem {

    private row: HTMLTableRowElement

    constructor(private organisationId: number, private user: UserItem) {
        this.row = document.createElement("tr")
    }

    public render() {
        render(html`
            <th scope="row">${this.user.mailAddress}</th>
            <td>${this.user.firstName || ""} ${this.user.lastName || ""}</td>
            <td>${this.user.phoneNumber}</td>
            <td>${this.user.isAdministrator ? "X" : ""}</td>
            <td>
                <button data-id="btnEdit" @click=${() => this.onEditClicked()} type="button" class="btn btn-hover-brand btn-elevate-hover btn-icon btn-sm btn-icon-md">
                    <i class="fas fa-edit"></i>
                </button> 
            </td>`, this.row)

        return this.row
    }

    private onEditClicked() {
        new UserEditDialog(this.organisationId, this.user).show()
    }
}