import Router from 'Router'

export type ViewCreatorFunction = (params: any) => HTMLElement

export function toElement(html: string) {
    const template = document.createElement("template")
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content
}

export function View(customElement: string, url: string, viewCreator: ViewCreatorFunction) {
    Router.register(url, viewCreator)
    return function (constructor: any) {
        customElements.define(customElement, constructor)
    }
}